<template>
  <div role="tablist">
    <template v-for="(order, index) in orders">
      <order-card :order="order" :key="index" :identifier="`order_accordion_${index}`" v-show="canShowOrderCard(order.status)"></order-card>
    </template>
  </div>
</template>

<script>
import OrderCard from './OrderCard'

export default {
  props: {
    orders: {
      type: Array,
      required: true
    },
    ordersFilter: {
      type: String
    }
  },
  methods: {
    canShowOrderCard (status) {
      // search criteria:
      // current = all != cancel and completed
      // canceled
      // all
      let canceled = ['canceled', 'paypay_canceled_reversal']
      let current = ['processing', 'pending', 'payment_review', 'pending_payment']
      if (this.ordersFilter === 'all-order') {
        return true
      } else if (this.ordersFilter === 'current') {
        return current.includes(status)
      } else if (this.ordersFilter === 'canceled') {
        return canceled.includes(status)
      }
    }
  },
  components: {
    OrderCard
  }
}
</script>
