<template>
  <b-card-body class="order-card__body">
    <div class="order-card__body_content">
      <div class="order-card__body_content_header">
        <div class="order-card__body_content_column">
          <div class="order-card__body_content_column-block column-s-a">
            <order-addresses :title="$t('order.shipping-address')" :address="shippingAddress"></order-addresses>
          </div>
        </div>
        <div class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <span class="label">{{ $t('order.shipping-method') }}</span>
            <div class="block-content">
              <p class="value">{{ order.shipping_method }}</p>
              <p class="value">{{ order.shipping_description }}</p>
            </div>
          </div>
        </div>
        <div class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <order-addresses :title="$t('order.billing-address')" :address="order.billing_address"></order-addresses>
          </div>
        </div>
        <div class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <span class="label">{{ $t('order.payment-method') }}</span>
            <div class="block-content">
              <p class="value text-capitalize">{{ $t(`order.payment-methods.${order.payment.method}`) }}</p>
              <p class="value">{{ order.payment.payment_description }}</p>
            </div>
            <div class="block-content" v-if="order.payment.method == 'banktransfer'">
              <p class="value-line"> {{ $t('order.bonificoBancario.coordinate_bancarie_label')}} {{$t('order.bonificoBancario.coordinate_bancarie_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.causa_pagamento_label') }} {{order.increment_id}} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.beneficiario_label') }} {{ $t('order.bonificoBancario.beneficiario_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.bank_label') }} {{ $t('order.bonificoBancario.bank_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.codice_swift_label') }} {{ $t('order.bonificoBancario.codice_swift_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.numero_di_conto_label') }} {{ $t('order.bonificoBancario.numero_di_conto_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.iban_label') }} {{ $t('order.bonificoBancario.iban_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.bank_city_label') }} {{ $t('order.bonificoBancario.bank_city_value') }} </p>
              <p class="value-line"> {{ $t('order.bonificoBancario.bank_state_label') }} {{ $t('order.bonificoBancario.bank_state_value') }} </p>
            </div>
          </div>
        </div>
      </div>
      <!-- ORDER ITEM LIST HERE -->
      <!-- <div class="order-card__body_content_items">
        <table-layout
        :items="getProductByOrderID"
        :tileComponent="tableTileComponent"
        :fields="fields" />
      </div> -->
      <!-- <div class="order-card__body_content_column column-cta" v-if="showCta">
        <div class="order-card__body_content_column-block">
          <div class="order-cta">
            <button class="btn btn-primary order-cta_single" @click.prevent="setCurrentOrder()">{{ $t('order.view-order') }}</button>
            <button class="btn btn-primary order-cta_single" @click.prevent="reorder()" :disabled="!isReordable">{{ $t('order.reorder') }}</button>
            <router-link
              v-if="showRefound"
              :to="{ name: 'newReturns', query: { orderId: order.entity_id }}"
              class="bcm-link bcm-link-underline-primary t-small order-cta_single"
            >{{ $t('order.refound') }}</router-link>

          </div>
        </div>
      </div> -->
      <table-products :items="order.items"></table-products>
      <!-- <div class="order-refund text-md-right">
        <button class="btn btn-none bcm-link bcm-link-underline-primary ml-auto order-cta_single" @click.prevent="goToRefound">{{ $t('order.refound') }}</button>
        <p class="mt-3 t-small" v-show="refundErrorMessage">{{ refundErrorMessage }}</p>
      </div> -->
    </div>
  </b-card-body>
</template>

<script>
// import OrderItemTile from 'theme/components/Tables/Tiles/OrderItemTile'
import OrderAddresses from './OrderAddresses'
import TableLayout from 'theme/components/Tables/TableLayout'
import { toast } from '@/modules/notifications'
import Logger from '@/services/Logger'
import TableProducts from 'plugins/Qapla/components/TableProducts'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    showCta: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // tableTileComponent: OrderItemTile,
      fields: [
        {
          key: 'name',
          label: this.$t('product')
        },
        {
          key: 'color',
          label: this.$t('color')
        },
        {
          key: 'size',
          label: this.$t('size')
        },
        {
          key: 'price',
          label: this.$t('price')
        },
        {
          key: 'qty',
          label: this.$t('Quantity')
        },
        {
          key: 'sub-total',
          label: this.$t('subtotal')
        }
      ],
      refundErrorMessage: ''
    }
  },
  computed: {
    /* getProductByOrderID () {
      return this.$store.getters['orders/getProductsByOrderID'](this.order.entity_id)
    }, */
    shippingAddress () {
      let shipping = {}
      // API
      if (this.order.extension_attributes.shipping_assignments.length) {
        shipping = this.order.extension_attributes.shipping_assignments[0].shipping.address
      }
      // GRAPHQL
      // if (this.order.shipping_info.shipping_address) {
      //   shipping = this.order.shipping_info.shipping_address
      // }
      return shipping
    },
    showRefound () {
      return this.order.status === 'complete'
    },
    isReordable () {
      return this.order.extension_attributes && this.order.extension_attributes.is_reordered === true
    }
  },
  methods: {
    setCurrentOrder () {
      this.$store.commit('orders/setOrder', this.order)
      this.$router.push({
        name: 'myOrder',
        params: { id: this.order.entity_id }
      })
    },
    reorder () {
      const _this = this
      this.$store.commit('ui/showLoader')
      this.$store.dispatch('user/reorder', { id: this.order.entity_id })
        .then(() => {
          _this.$router.push({
            name: 'cart'
          })
        })
        .catch(err => {
          toast.error(_this.$t('GenericError'))
          Logger.error(err)
        })
        .finally(() => {
          _this.$store.commit('ui/hideLoader')
        })
    },
    goToRefound () {
      if (this.showRefound) {
        // go to refund
        this.$router.push({ name: 'newReturns', query: { orderId: this.order.entity_id } })
      } else {
        this.refundErrorMessage = this.$t('order.refund.error-message')
      }
    }
  },
  components: {
    OrderAddresses,
    TableLayout,
    // OrderItemTile,
    TableProducts
  }
}
</script>
