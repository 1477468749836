<template>
  <b-card no-body class="order-card" :class="{'enable-block-tracks': tracks.length > 0}">
    <b-card-header header-tag="header" role="tab" class="order-card__header" v-b-toggle="identifier">
      <div class="card-overlay" @click.prevent="isCollapsed = !isCollapsed"></div>
      <div class="order-card__header-block block-order-id">
        <span class="label">{{ $t('orderSummary.order') }}</span>
        <span class="text">#{{order.increment_id}}</span>
      </div>
      <div class="order-card__header-block block-status">
        <span class="label">{{ $t('orderSummary.status') }}</span>
        <span class="text">{{order.status}}</span>
      </div>
      <div class="order-card__header-block block-creation">
        <span class="label">{{ $t('orderSummary.date') }}</span>
        <span class="text">{{ createdAt }}</span>
      </div>
      <div class="order-card__header-block block-total">
        <span class="label">{{ $t('orderSummary.total') }}</span>
        <span class="text">{{ order.grand_total | price($store) }}</span>
      </div>
      <div class="order-card__header-block block-tracks">
        <template v-if="tracks.length > 0">
          <a :href="track" target="_black" v-for="(track, index) in tracks" :key="index">
            {{ $t('orderSummary.followYourOrder')}} <span v-show="index">({{index}})</span>
          </a>
        </template>
        <span v-else>{{$t('orderSummary.noTracks')}}</span>
      </div>
      <div class="order-card__header-block block-collapse-arrow" :class="{'collapsed': isCollapsed}">
        <i :class="['icomoon-icon', isCollapsed ? 'icon-minus' : 'icon-plus']"></i>
      </div>
    </b-card-header>
    <b-collapse
      :id="identifier"
      accordion="my-accordion"
      role="tabpanel"
      class="order-collapse"
    >
      <order-card-body :order="order"></order-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import dayjs from 'dayjs'
import OrderCardBody from './OrderCardBody'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isCollapsed: false
    }
  },
  computed: {
    createdAt () {
      return dayjs(this.order.created_at).format('DD/MM/YYYY')
    },
    tracks () {
      if (this.order && this.order.qapla_tracks) {
        return this.order.qapla_tracks
      }
      return []
    }
  },
  methods: {
    trackOrder (track) {
      window.open(track)
    }
  },
  components: {
    OrderCardBody
  }
}
</script>
